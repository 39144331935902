import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable()

@Injectable({
  providedIn: 'root'
})
export class BaseHttpServiceService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  
  constructor(private _http: HttpClient) { }
  getHeaders(): HttpHeaders {
    return this.headers;
  }

  _POST(url, body): Observable<any> {
    return this._http.post(url, body);
  }

}
