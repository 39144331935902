import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { BootCampVideosComponent } from './pages/Training/boot-camp-videos/boot-camp-videos.component';
import { LoginModule } from './pages/login/login.module';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';

export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponent, children: [
            {path:'',redirectTo:'/login', pathMatch: 'full' },
           { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } }, 
          //  { path: '', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
            { path: 'Clients', loadChildren: () => import ('./pages/Clients/clients/clients.module').then(m => m.ClientModule), data: { breadcrumb: 'Clients' } }, 
            { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), data: { breadcrumb: 'Users' } },
            { path: 'Training', loadChildren: () => import('./pages/Training/Training.module').then(m => m.TrainingModule), data: { breadcrumb: 'Training' } },
           // { path: 'bootCampVideo', component: BootCampVideosComponent, pathMatch: 'full' },
            { path: 'events', component: BootCampVideosComponent, pathMatch: 'full' },
            { path: 'profile', loadChildren: () => import ('./pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' } }, 
            { path: 'Leads', loadChildren: () => import ('./pages/Leads/Leads.module').then(m => m.LeadsModule), data: { breadcrumb: 'Leads' } }, 
            { path: 'ProfileDetails', loadChildren: () => import('./pages/Profile_Details/profile-user-info/profileUserInfo.module').then(m => m.ProfileUsers), data: { breadCrumb: 'profileDetails' } },
            { path: 'Support', loadChildren: () => import('./pages/Supports/Support.module').then(m => m.SupportModule), data: { breadCrumb: 'Support' } },
            { path: 'Shop', loadChildren: () => import('./pages/Shop/Shop.module').then(m => m.ShopModule), data: { breadCrumb: 'Shop' } },
            { path: 'dynamic-menu', loadChildren: () => import('./pages/dynamic-menu/dynamic-menu.module').then(m => m.DynamicMenuModule), data: { breadcrumb: 'Dynamic Menu' }  },          
            { path: 'ui', loadChildren: () => import('./pages/ui/ui.module').then(m => m.UiModule), data: { breadcrumb: 'UI' } },
            { path: 'mailbox', loadChildren: () => import('./pages/mailbox/mailbox.module').then(m => m.MailboxModule), data: { breadcrumb: 'Mailbox' } },
            { path: 'chat', loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule), data: { breadcrumb: 'Chat' } },
            { path: 'form-controls', loadChildren: () => import('./pages/form-controls/form-controls.module').then(m => m.FormControlsModule), data: { breadcrumb: 'Form Controls' } },
            { path: 'tables', loadChildren: () => import('./pages/tables/tables.module').then(m => m.TablesModule), data: { breadcrumb: 'Tables' } },
            { path: 'schedule', loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.ScheduleModule), data: { breadcrumb: 'Schedule' } },
            { path: 'maps', loadChildren: () => import('./pages/maps/maps.module').then(m => m.MapsModule), data: { breadcrumb: 'Maps' } },
            { path: 'charts', loadChildren: () => import('./pages/charts/charts.module').then(m => m.ChartsModule), data: { breadcrumb: 'Charts' } },
            { path: 'drag-drop', loadChildren: () => import('./pages/drag-drop/drag-drop.module').then(m => m.DragDropModule), data: { breadcrumb: 'Drag & Drop' } },
            { path: 'icons', loadChildren: () => import('./pages/icons/icons.module').then(m => m.IconsModule), data: { breadcrumb: 'Material Icons' } },
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
            { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } },
            { path: 'search/:name', component: SearchComponent, data: { breadcrumb: 'Search' } },
            
        ]
    },
    { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'signup/:refid', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
    { path: 'signup', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
    { path: 'resetpassword', component: ResetpasswordComponent, pathMatch: 'full' },
    { path: 'resetpassword/:refid', component: ResetpasswordComponent, pathMatch: 'full' },
    { path: 'viewplan', loadChildren: () => import('./pages/viewplan/viewplan.module').then(m => m.ViewplanModule) },


    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: '**', component: NotFoundComponent }
];
 

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }