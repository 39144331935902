import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseHttpServiceService } from './base-http-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  baseUrl: string = environment.config.API;
  constructor(public baseHttpService: BaseHttpServiceService) { }

 

  public login(obj) {
    let url = this.baseUrl + 'Authenticate/Login'
   
    return this.baseHttpService._POST(url, obj)
  }
  public getUserRole() {
    let url = this.baseUrl + 'Authenticate/GetUserRole?uid='+localStorage.Uid;
    return this.baseHttpService._POST(url, {});
  }
  public GetUserSubscriptionDetails() {
    let url = this.baseUrl + 'Authenticate/GetUserSubscriptionDetails?uid='+localStorage.Uid;
    return this.baseHttpService._POST(url, {});
  }
  public GetBootcampDetails() {
    let url = this.baseUrl + 'Authenticate/GetBootcampDetails?uid='+localStorage.Uid;
    return this.baseHttpService._POST(url, {});
  }
  public GetUserByEmail(email:string) {
    let url = this.baseUrl + 'Authenticate/GetUserByEmail?email='+email;
    return this.baseHttpService._POST(url, {});
  }
  public SendResetPasswordLinkToUser(email:string,encUserId:string,userid:number) {
    let url = this.baseUrl + 'Authenticate/SendResetPasswordLinkToUser?customeremail='+email+'&encUserId='+encUserId+'&userid='+userid;
    return this.baseHttpService._POST(url, {});
  }
}
