import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { profileUserInfo } from '../Profile_Details/profile-user-info/profileUserInfo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { matchingPasswords } from 'src/app/theme/utils/app-validators';
import { Settings } from 'src/app/app.settings.model';
import { AppSettings } from 'src/app/app.settings';
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss'],
  providers: [profileUserInfo]
})
export class ResetpasswordComponent implements OnInit {
  public personalForm: UntypedFormGroup;
  newpassword: string = "";
  // oldpassword: string = "";
  passwordstrength: number = 0;
  public settings: Settings;
  constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, public appSettings: AppSettings, private pinfo: profileUserInfo, private _snackbar: MatSnackBar,
    public router: Router,) { this.settings = this.appSettings.settings; }
  decrypted: string = "";
  tokenFromUI: string = "0127849123345656";
  ngOnInit() {


    this.settings.loadingSpinner = false;
    this.personalForm = this.formBuilder.group({

      //'oldpassword': ['', Validators.required],
      'newpassword': ['', Validators.required],
      // 'gender': [''],
      'ConfirmPassword': ['', Validators.required],

    }, { validator: matchingPasswords('newpassword', 'ConfirmPassword') });
  }

  // public onSubmit(values:Object):void {
  //     // if (this.personalForm.valid) {
  //     //     // this.router.navigate(['pages/dashboard']);
  //     // }
  // }
  changepassword() {
    if (!this.personalForm.valid) {
      this._snackbar.open("Please fill all the mandatory fields", "Ok", {
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return;
    }
    else {
      this.settings.loadingSpinner = true;
      // let userid=decryptUsingAES256
      this.route.params.subscribe(params => {

        let uid = this.decryptUsingAES256(params['refid'] + "==");
        if (uid) { }
        let ChangePassword: any = {

          // "UserId": localStorage.Uid, 
          "UserId": Number(uid),
          // "OldPassword": this.oldpassword.toString(),
          "NewPassword": this.newpassword.toString(),
          "PasswordResetCode": params['refid'] + "=="

        }

        this.pinfo.ResetPassword(ChangePassword).subscribe(res => {
          debugger;
          let message = "Your Password successfully changed, please login with new password";
          if (res.status == "false") {
            message = res.message;
          }
          if (res.status == "true") {
            this.router.navigate(["/login"]);
          }
          this.settings.loadingSpinner = false;
          this._snackbar.open(message, "Ok", {
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        })
      }
      );

    }
  }
  checkPasswordstrength(e) {



    this.passwordstrength = 100 * this.newpassword.length / 8;

  }
  decryptUsingAES256(val): string {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    this.decrypted = CryptoJS.AES.decrypt(
      val.replaceAll(" ", "+"), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    return this.decrypted;

  }
  GoToLogin()
  {
    this.router.navigate(["/login"]);
  }
}

export function emailValidator(control: UntypedFormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }


}
