import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { profileUserInfo } from 'src/app/pages/Profile_Details/profile-user-info/profileUserInfo.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CommonserviceService } from 'src/app/commonservice.service';
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [profileUserInfo]
})
export class UserMenuComponent implements OnInit {
  private subscriptionName: Subscription;
  public userImage:any = '../../../../assets/img/app/no-image.png';
  public username:string="";
  constructor( public router: Router,private pinfo: profileUserInfo,protected sanitizer: DomSanitizer,
    private commonservice:CommonserviceService) {

    
 this.commonservice.getMessage().subscribe(message => { 
  
  const value1 = this.sanitizer.bypassSecurityTrustUrl(message.text.replaceAll('(see https://g.co/ng/security#xss)','').replaceAll('SafeValue must use [property]=binding:',''));
  // const value1= this.sanitizer.bypassSecurityTrustHtml(res[0].photo);
  
  if(value1 != undefined && value1.toString().indexOf('undefined')<0)
  {
   this.userImage = value1;
  }
            // this.userImage = message;
             });
   }

  ngOnInit() {
    this.username=localStorage["UserFirstName"]+" "+ localStorage["UserLastName"];
    this.pinfo.GetUserProfile(Number(localStorage.Uid)).subscribe(res => {
      if (res) {
        const value1 = this.sanitizer.bypassSecurityTrustUrl(res[0]?.photo?.replaceAll('(see https://g.co/ng/security#xss)','').replaceAll('SafeValue must use [property]=binding:',''));
        // const value1= this.sanitizer.bypassSecurityTrustHtml(res[0].photo);
       
         if(value1 != undefined && value1.toString().indexOf('undefined')<0)
         {
         this.userImage = value1;
         }
      }
    });
  }
Logout()
{
  
  localStorage.clear();
  this.router.navigate(['/login']);
}

}
