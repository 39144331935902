
import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { emailValidator, matchingPasswords } from '../../theme/utils/app-validators';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { UsersService } from '../users/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  public form: UntypedFormGroup;
  public settings: Settings;
  public routeSub: any;
  public userPrefix: string;
  public email: string;
  public name: string;
  public lastName: string;
  public password: string;
  public businessPhone: string;
  public MobilePhone: string;
  public InsuranceLicNumber: string;
  public ContractedwithiCanCarrier: any;
  public carrierName: string;
  public agentNumber: string;
  public Howdidyouhear: any;
  public other: string;
  public Whoreferredyou: string;
  public Whosentemail: any;
  public Whichsearchengine: any;
  public Whichsocialplatform: any;
  public Agency:string="";
  sendMail: boolean = false;
  todate:Date;
  userrole: any;
  userAgency: any;
  ShowAgency: boolean = false;
  ShowPasswoerdrow: boolean = true;
  ShowRole:boolean=false;
  isReadonly=false;
  createuserbuttontext:string="Create User";
  public salutations = [
    { id: 1, name: 'Mr' },
    { id: 2, name: 'Ms' },
    { id: 3, name: 'Mrs' },
    { id: 4, name: 'Dr' }
  ];
  public ContractedwithCarriers = [
    { id: 1, title: 'No' },
    { id: 2, title: 'Yes' },
    { id: 3, title: "Recently Submitted (use 'pending' for agent number)" },

  ]
  public HowdidyouhearaboutLifeplan = [
    { id: 1, title: 'I was referred' },
    { id: 2, title: 'I received an email' },
    { id: 3, title: "Search engine" },
    { id: 4, title: "Social Media" },
    { id: 5, title: "Other" }
  ]
  public Whosentyouemail = [
    { id: 1, title: 'LifePlan Marketing' },
    { id: 2, title: 'A colleague' },
    { id: 3, title: "A friend" },
    { id: 4, title: "Other" }
  ]
  public searchEngines = [
    { id: 1, title: 'DuckDuckGo' },
    { id: 2, title: 'google' },
    { id: 3, title: "bing" },
    { id: 4, title: "yahoo" },
    { id: 5, title: "baidu" },
    { id: 6, title: "aol" },
    { id: 7, title: "ask" },
    { id: 8, title: "excite" },
    { id: 9, title: "yandex" },
    { id: 10, title: "lycos" },
    { id: 11, title: "other" }
  ]
  public socialplatforms = [
    { id: 1, title: 'LinkedIn' },
    { id: 2, title: 'Facebook' },
    { id: 3, title: "Twitter" },
    { id: 4, title: "Instagram" },
    { id: 5, title: "Tumblr" },
    { id: 6, title: "Other" }

  ]
  public UserRoles: any = [];
  public Agencies: any = [];
  constructor(public appSettings: AppSettings, public fb: UntypedFormBuilder, public router: Router, private route: ActivatedRoute,
    public usersService: UsersService, public _snackbar: MatSnackBar,private datePipe: DatePipe
    , @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CreateUserComponent>,
  ) {
    this.settings = this.appSettings.settings;

    this.form = this.fb.group({
      'userprefix': ['', Validators.required],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3)])],
      'lastName': [null, Validators.compose([Validators.required, Validators.minLength(3)])],
      'email': [null, Validators.compose([Validators.required, emailValidator])],
      'password': ['', Validators.required],
      'confirmPassword': ['', Validators.required],
      'MobilePhone': [''],
      'businessPhone': ['', Validators.required],
      'InsuranceLicNumber': [''],
      'Agency':[''],
      'ContractedwithiCanCarrier': [this.ContractedwithiCanCarrier],
      'carrierName': [''],
      'agentNumber': [''],
      'Howdidyouhear': [''],
      'other': [''],
      'Whoreferredyou': [''],
      'Whosentemail': [''],
      'Whichsearchengine': [''],
      'Whichsocialplatform': [''],
      'userrole': ['', Validators.required],
      'userAgency': ['', Validators.required],
      'sendMail': [this.sendMail],
      'todate':[this.todate]
    }, { validator: matchingPasswords('password', 'confirmPassword') });

    this.form.get('ContractedwithiCanCarrier').valueChanges.subscribe(val => {

      if (Number(val) > 1) {

        this.form.controls['agentNumber'].setValidators([Validators.required]);
        this.form.controls['carrierName'].setValidators([Validators.required]);

      } else {

        this.form.controls['agentNumber'].clearValidators();
        this.form.controls['carrierName'].clearValidators();

      }
      this.form.controls['agentNumber'].updateValueAndValidity();
      this.form.controls['carrierName'].updateValueAndValidity();
    });

  }
  // public onSubmit(values:Object):void {
  //   if (this.form.valid) {
  //     this.router.navigate(['/login']);
  //   }
  // }
  CreateUser() {

    if (!this.form.valid) {
      this._snackbar.open("Please fill all mandatory.", "Ok", {
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return;
    }
    else {
      if (this.data.mode == 'edit') {
      
      
        let UpdateUserRequest = {
          "UserId":localStorage.Uid,
          "AppUserID": Number(this.data.appuserid),
          "Prefix": this.userPrefix,
          "NameFirst": this.name,
          "NameLast": this.lastName,
          "EmailAddress": this.email,

          "mobilephone": this.MobilePhone,
          "businessphone": this.businessPhone,
          "LicenseNumber": this.InsuranceLicNumber?this.InsuranceLicNumber:'',
          "Agency":this.Agency,

          "AgencyId": this.userAgency == undefined ? 0 : Number(this.userAgency),
          "RoleId": this.userrole == undefined ? 2 : Number(this.userrole),
          "ExpiryDate":this.todate
        }

        this.usersService.UpdateUserById(UpdateUserRequest).subscribe(res => {
          this._snackbar.open("User updated successfully.", "Ok", {
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.dialogRef.close();

        })

      }
      else {
        //this.isReadonly=false;
        let CreateUserRequest = {
          "Prefix": this.userPrefix,
          "AppUserName": this.email,
          "NameFirst": this.name,
          "NameLast": this.lastName,
          "EmailAddress": this.email,
          "Password": this.password,
          "Mobilephone": this.MobilePhone,
          "BusinessPhone": this.businessPhone,
          "LicenseNumber": this.InsuranceLicNumber?this.InsuranceLicNumber:'',
          "Agency":this.Agency,
          "IsiCanContracted": true,
          "CarrierName": this.carrierName == undefined ? '' : this.carrierName,
          "AgentNumber": this.agentNumber == undefined ? '' : this.agentNumber,
          "HowdidReferred": this.Howdidyouhear == undefined ? '' : this.Howdidyouhear.toString(),
          "Referred_Source": this.Whoreferredyou == undefined ? '' : this.Whoreferredyou,
          "ReferralCode": this.routeSub == undefined ? '' : this.routeSub,
          "AgencyId": this.userAgency == undefined ? 0 : Number(this.userAgency),
          "RoleId": this.userrole == undefined ? 2 : Number(this.userrole),
          "createdBy":localStorage.Uid,
          "ExpiryDate":this.todate
        }

        this.usersService.CreteUser(CreateUserRequest).subscribe(res => {
          let result = res;

          if (result['status'] == 'true') {
            this._snackbar.open("User created successfully.", "Ok", {
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.dialogRef.close();
          }
          else {
            this._snackbar.open(result['message'], "Ok", {
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          }
        });
      }
    }
  }

  ngAfterViewInit() {

    this.route.params.subscribe(params => {

      this.routeSub = params['refid']; //log the value of id

    });
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    });
  }

  ngOnInit(): void {
    if (localStorage.UserRole == "5") {
      this.ShowAgency = true;
      this.form.controls['userAgency'].setValidators([Validators.required]);
    }
    else {
      this.ShowAgency = false;
      this.userAgency = localStorage.AgencyId;
      this.form.controls['userAgency'].clearValidators();
    }
    if(localStorage.UserRole == "5" || localStorage.UserRole == "1")
    {
      this.ShowRole = true;
      this.form.controls['userrole'].setValidators([Validators.required]);
    }
    else
    {
      
      this.ShowRole = false;
      this.form.controls['userrole'].clearValidators();
      this.userrole=2;

    }
    this.getuserRoles();
    this.getAgencies();
    if (this.data.mode == 'edit') {
      
        this.isReadonly=true;
      this.createuserbuttontext="Update User"
      this.ShowPasswoerdrow = false;
      this.form.controls['confirmPassword'].clearValidators();
      this.form.controls['password'].clearValidators();
      this.FillUserData();


    }
  }
  FillUserData() {
    //alert(this.data.appuserid);
    this.usersService.GetUserById(Number(this.data.appuserid)).subscribe(res => {
      //alert(JSON.stringify(res));
      if (res) {
        
        this.userPrefix = res[0]?.Prefix.length > 0 ? res[0]?.Prefix : 'Mr';
        this.name = res[0]?.NameFirst;
        this.lastName = res[0]?.NameLast;
        this.email = res[0]?.EmailAddress;
        this.MobilePhone = res[0]?.mobilephone;
        this.businessPhone = res[0]?.businessphone;
        this.InsuranceLicNumber = res[0]?.LicenseNumber;
        this.userAgency = res[0]?.AgencyId;
        this.userrole = res[0]?.RoleId;
        this.Agency=res[0]?.Agency;
        if(this.datePipe.transform(res[0]?.ExpiryDate))
        {
        this.todate= new Date(this.datePipe.transform(res[0]?.ExpiryDate, 'yyyy-MM-dd'));
        }
      }
    })
  }
  getuserRoles() {
    this.usersService.GetuserRoles().subscribe(res => {
      if (res.status == 'true') {
        this.UserRoles = res.data;
      }

    });

  }
  getAgencies() {
    this.usersService.GetAgencies().subscribe(res => {
      if (res.status == 'true') {
        this.Agencies = res.data;
      }

    });

  }


}
