import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdditionalTrainingModel, BootCampHandOutsModel, BootcampVideos, howToVideos, RequiredVideos } from '../Profile_Details/profile-user-info/profileUserInfo.model';
import { environment } from 'src/environments/environment';
import { UsersList } from '../users/user.model';

@Injectable()
export class UsersService {
    public _enveronment = environment.config.API;

    public getAllUsersApi: string = this._enveronment + "User/ListUsers";
    public getReferralsApi: string = this._enveronment + "User/ListReferrals";
    public createuserApi: string = this._enveronment + "User/Create";
    public getuserRoles: string = this._enveronment + "Authenticate/GetRoles";
    public getAgencies: string = this._enveronment + "Authenticate/GetAgencies";

    public activeDeActiveUserById: string = this._enveronment + "User/ActiveDeActiveUserById";
    public reActivateUserUserById: string = this._enveronment + "User/ReActivateUserById";
    public deleteUserById: string = this._enveronment + "User/DeleteUserById";
    public getUserById: string = this._enveronment + "User/GetUserById";
    public updateUserById: string = this._enveronment + "User/UpdateUserUserById";
    public getSupperAdminDashboardCounts: string = this._enveronment + "Authenticate/GetSupperAdminDashboardCounts";
    constructor(public http: HttpClient) { }

    getAllUsers(): Observable<any[]> {
        return this.http.get<any[]>(this.getAllUsersApi + "?userid=" + localStorage.Uid);
    }
    getReferrals(obj): Observable<any> {
        return this.http.get<any[]>(this.getReferralsApi + "?userid=" + obj);
    }
    CreteUser(obj): Observable<any[]> {

        return this.http.post<any[]>(this.createuserApi, obj);
    }
    GetuserRoles(): Observable<any> {
        return this.http.get<any[]>(this.getuserRoles + "?uid=" + localStorage.Uid);
    }
    GetAgencies(): Observable<any> {
        return this.http.get<any[]>(this.getAgencies + "?uid=" + localStorage.Uid);
    }
    ActiveDeActiveUserById(userid, status): Observable<any[]> {

        return this.http.post<any[]>(this.activeDeActiveUserById + "?userId=" + userid + "&status=" + status, {});
    }
    ReActivateUserUserById(userid): Observable<any[]> {

        return this.http.post<any[]>(this.reActivateUserUserById + "?userId=" + userid , {});
    }
    GetUserById(userid: number): Observable<any[]> {
        return this.http.get<any[]>(this.getUserById + "?userid=" + userid);
    }
    UpdateUserById(obj): Observable<any[]> {

        return this.http.post<any[]>(this.updateUserById, obj);
    }
    DeleteUserById(userid): Observable<any[]> {

        return this.http.post<any[]>(this.deleteUserById + "?userId=" + userid + "&status=" + status, {});
    }

    GetSupperAdminDashboardCounts(fromdate:any,todate:any,agencyid:number): Observable<any> {
        return this.http.get<any[]>(this.getSupperAdminDashboardCounts + "?fromdate=" + fromdate+"&todate="+todate+"&agencyid="+agencyid);
    }

} 