import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  url;
  format;
  urlSafe: SafeResourceUrl;
constructor(public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
public vdiourl:string="";
  ngOnInit(): void {
    this.vdiourl=this.data.name;
   // this.vdiourl="https://www.youtube.com/embed/dlVT6YUb0f0?autoplay=1&modestbranding=1&&autohide=1&showinfo=0&rel=0";
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.vdiourl);
  //   setTimeout(() => {
  //     document.getElementById('video1').play();
  // }, 1000);
  
    //if(this.data)
    //var lastFive = this.data.name.substr(this.data.length - 3);
    //console.log(lastFive)
  }
  
   
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  
}
