import { Component, ViewChild } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { Router } from '@angular/router';
import { AuthenticateService } from './services/authenticate.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AuthenticateService]
})
export class AppComponent {
  public settings: Settings;
  constructor(public appSettings: AppSettings, public router: Router,
   // public _authenticate: AuthenticateService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    // this._authenticate.getUserRole().subscribe(res => {
    //   if(res.Success==true)
    //   {
    //     alert(res);
    //   }
    // });

    let path = document.location.pathname;
    let currentpath = '';
    if (path.length > 1) {
      currentpath = path[path.length - 1];
    }
    if (!localStorage["SID"] && path.indexOf('signup') == -1 && path.indexOf('viewplan') == -1 && path.indexOf('resetpassword')  == -1 )

      this.router.navigate(['/login']);
  }
}