import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { TablesService, Element } from '../../tables/tables.service';
import { TrainingsService } from '../training.service'
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-boot-camp-videos',
  templateUrl: './boot-camp-videos.component.html',
  styleUrls: ['./boot-camp-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TrainingsService]
})
export class BootCampVideosComponent implements OnInit {
  todayDate:Date=new Date();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('CreateEventTemplet', { static: true }) CreateEventTemplet: TemplateRef<any>;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  public eventForm: UntypedFormGroup;
  dataLength: number;
  matDialogRef: MatDialogRef<DialogComponent>;
  name: string = "";
  matDialog: any;
  searchCtrl = new FormControl();
  hideClear: boolean = false;
  MatSort: any;
  showCreateEvent:boolean=false;
  eventDate:any;
  eventTitle:string;
  EventUrl:string;
  Description:string;
  eventDialogHeader:string="Create Event";
  eventId:number=0;
  CreateEVentbuttonText:string="Create Event";
  openDialog(val): void {
    // let dialogRef = this.dialog.open(DialogComponent, {
    //   height: '300px',
    //   width: '700px',
    //   data: { name: val }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    // });
    window.open(val);

  }

  public dataSource: any;
  public settings: Settings;
  recordVideos: any = [];
  //public displayedColumns = ['CreatedDate', 'VideoTitle', 'LifePlanVersion', 'actions'];
  public displayedColumns = ['EventDate', 'EventTitle', 'Description','view','actions'];

  constructor(public appSettings: AppSettings,
    private tablesService: TablesService,
    public trainingService: TrainingsService,
    public dialog: MatDialog,private formBuilder: UntypedFormBuilder,public _snackbar: MatSnackBar
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    if(localStorage.UserRole==5)
    {
      this.displayedColumns =  ['EventDate', 'EventTitle', 'Description','view','actions'];
      this.showCreateEvent=true;
    }
    else
    {
      this.displayedColumns =  ['EventDate', 'EventTitle', 'Description','view'];
      this.showCreateEvent=false;
    }
    this.eventForm = this.formBuilder.group({
      eventDate: [this.eventDate, Validators.required],
      eventTitle: [this.eventTitle, Validators.required],
      EventUrl: [this.EventUrl,Validators.required],
      Description: [this.Description],
      

    });

    //this.getVideos();
    this.getEvents();
  }

  /* List Of Required Videos Calling */
  public getVideos(): void {
    this.dataSource = null; //for show spinner each time
    this.trainingService.getBootcampVideos()
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.data = data;
        this.dataLength = data.length;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      );
  }
  public getEvents(): void {
    this.dataSource = null; //for show spinner each time
    this.trainingService.GetBootcampEvents()
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.data = data;
        this.dataLength = data.length;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      );
  }
  OpenCreateEventDialog(templateRef: TemplateRef<any>)
  {
    this.CreateEVentbuttonText="Create Event";
    this.eventDialogHeader="Create Event";
    this.eventId=0;
    this.eventDate= "";
    this.eventTitle="";
 this.EventUrl="";
this.Description=  "";  
this.matDialogRef= this.dialog.open(templateRef, {
              height: 'auto',
              width: '600px',
              
            });
  }
  OpenEditEventDialog(templateRef: TemplateRef<any>,userrow:any)
  {
    this.CreateEVentbuttonText="Update Event";
    this.eventDialogHeader="Edit Event";
    this.eventDate= userrow.EventDate;
    if(userrow.EventDate<new Date())
    {
    this.todayDate=userrow.EventDate;
    }
    else
    {
      this.todayDate=new Date();
    }
    this.eventTitle=userrow.EventTitle;
 this.EventUrl=userrow.EventUrl;
this.Description=  userrow.Description;  
    // let CreateUpdateBootcampEventsModel={
    //   "EventId":Number(userrow.EventId),
    //   "EventDate": userrow.EventDate,
    //   "EventTitle":userrow.EventTitle,
    //   "EventUrl":userrow.EventUrl,
    //   "Description":userrow.Description,
    //   "isActive":true,
    //   "IsDeleted":true,
    //   "CreatedBy":Number(localStorage.Uid),
    //   "AgencyId":Number(localStorage.AgencyId)
    // }
    
    // this.CreateUpdateEvent(CreateUpdateBootcampEventsModel,"Event deleted.");
    this.eventId=userrow.EventId;

    this.matDialogRef= this.dialog.open(templateRef, {
              height: 'auto',
              width: '600px',
              
            });
  }
  CreateEvent()
  {
    if (!this.eventForm.valid) {
      this._snackbar.open("Please fill all the mandatory fields", "Ok", {
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return;
    }
    let CreateUpdateBootcampEventsModel={
      "EventId":this.eventId,
      "EventDate":this.eventDate,
      "EventTitle":this.eventTitle,
      "EventUrl":this.EventUrl,
      "Description":this.Description,
      "isActive":true,
      "IsDeleted":false,
      "CreatedBy":Number(localStorage.Uid),
      "AgencyId":Number(localStorage.AgencyId)
    }
    let message="Event created successfully.";
    if(this.eventId>0)
    {
      message="Event updated successfully.";
    }
    this.CreateUpdateEvent(CreateUpdateBootcampEventsModel,message);

  }

  CreateUpdateEvent(CreateUpdateBootcampEventsModel:any,msg:string)
  {
    let result:boolean;
    this.trainingService.CreateEvent(CreateUpdateBootcampEventsModel).subscribe(res=>
      {
       
        if(res.status=="true")
        {
          this._snackbar.open(msg, "Ok", {
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.matDialogRef.close();
          this.getEvents();
         
          

        }
        else
        {
          this._snackbar.open(res.message, "Ok", {
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          
          
        }
        
      });
     

      
  }
  DeleteEvent(userrow:any)
  {
    
    let CreateUpdateBootcampEventsModel={
      "EventId":Number(userrow.EventId),
      "EventDate": userrow.EventDate,
      "EventTitle":userrow.EventTitle,
      "EventUrl":userrow.EventUrl,
      "Description":userrow.Description,
      "isActive":true,
      "IsDeleted":true,
      "CreatedBy":Number(localStorage.Uid),
      "AgencyId":Number(localStorage.AgencyId)
    }
    
    this.CreateUpdateEvent(CreateUpdateBootcampEventsModel,"Event deleted.");
   
  }
  /* Search Function Start */

  applyFilter(value: any) {
    this.dataSource.filter = value.trim().toLowerCase();
    //  this.isNoRecords = this.dataSource.filteredData.length > 0 ? false : true;
    this.dataSource.data = this.dataSource.filteredData;
    this.dataLength = this.dataSource.data.length;
    this.hideClear = true;
    if (value.trim() === "") {
      this.hideClear = false;
      //this.getVideos();
      this.getEvents();
    }
  }

  clearSearch() {
    this.searchCtrl.setValue('');
    this.hideClear = false;
    //this.getVideos();
    this.getEvents();
  }
  /* Search Function End */

   /* sorting  function Start Here */

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

 
}
