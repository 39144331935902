import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdditionalTrainingModel, BootCampHandOutsModel, BootcampVideos, howToVideos,BootcampEvents,RequiredVideos } from './profileUserInfo.model';
import { environment } from 'src/environments/environment';
// import { UsersList } from '../../users/user.model';

@Injectable()
export class profileUserInfo {
    public _enveronment=environment.config.API;
    public url = this._enveronment+"Training/GetrequiredVideos";
    public GetRequiredVideosurl = this._enveronment+"Training/GetrequiredVideos";
    public GetBootcampVideosUrl = this._enveronment+"Training/GetBootcampVideos";
    public getBootcampEvents = this._enveronment+"Training/GetBootcampEvents";
    public GetAdditionalTrainingUrl = this._enveronment+"Training/GetAdditionalTrainingVideos";
    public GetHowToVideos = this._enveronment+"Training/GetHowToVideos";
    public getBootCampHandOuts = this._enveronment+"Training/GetBootCampHandoutFiles";
    public getuserProfile = this._enveronment+"User/GetUserProfile";
    public updateUserProfile = this._enveronment+"User/UpdateUserProfile";
    public updateMailingAddress = this._enveronment+"User/UpdateMailingAddress";
    public getMObileNotificationDetails= this._enveronment+"User/GetMobilePhoneDetails";
    public updateUserTelephoneNo= this._enveronment+"User/UpdateUserTelephoneNo";
    public updateUserTelephoneNotificationSettings= this._enveronment+"User/UpdateUserTelephoneNotificationSettings";
    public getAdvisorSite = this._enveronment+"User/GetAdvisorSite";
    public getAdvisorSiteByUserID = this._enveronment+"User/GetAdvisorSiteByUserID";
    public updateAdvisorSite = this._enveronment+"User/UpdateAdvisorSite";
   
    public getStates=this._enveronment+"User/GetStates";
    public changepassword=this._enveronment+"User/UpdatePassword";
    public resetpassword=this._enveronment+"User/ResetPassword"
    constructor(public http: HttpClient) { }
    
    
    getRequiredVideos(): Observable<RequiredVideos[]> {
        return this.http.get<RequiredVideos[]>(this.GetRequiredVideosurl);
    }
    getBootcampVideos(): Observable<BootcampVideos[]> {
        return this.http.get<BootcampVideos[]>(this.GetBootcampVideosUrl);
    }
    GetBootcampEvents(): Observable<BootcampEvents[]> {
        return this.http.get<BootcampEvents[]>(this.getBootcampEvents);
    }
    getBootcampHandOutsVideos(): Observable<BootCampHandOutsModel[]> {
        return this.http.get<BootCampHandOutsModel[]>(this.getBootCampHandOuts);
    }
    getAdditionalTraining(): Observable<AdditionalTrainingModel[]> {
        return this.http.get<AdditionalTrainingModel[]>(this.GetAdditionalTrainingUrl);
    }
    GetHowToVideosData(): Observable<howToVideos[]> {
        return this.http.get<howToVideos[]>(this.GetHowToVideos);
    }

    addUser(user: RequiredVideos) {
        return this.http.post(this.url, user);
    }

    updateUser(user: RequiredVideos) {
        return this.http.put(this.url, user);
    }

    deleteUser(id: number) {
        return this.http.delete(this.url + "/" + id);
    }
    GetUserProfile(obj): Observable<any[]> {
        return this.http.get<any[]>(this.getuserProfile+"?userid="+obj);
    } 
    GetStates(): Observable<any[]> {
        debugger;
        return this.http.get<any[]>(this.getStates);
    }
    UpdateUserProfile(obj: any): Observable<any> {
        
        return this.http.post<any>(this.updateUserProfile, obj);
    }
    UpdateUserTelephoneNo(obj: any): Observable<any> {
        return this.http.post<any>(this.updateUserTelephoneNo, obj);
    }
    UpdateUserTelephoneNotificationSettings(obj: any): Observable<any> {
        return this.http.post<any>(this.updateUserTelephoneNotificationSettings, obj);
    }
    UpdateMailingAddress(obj: any): Observable<any> {
        return this.http.post<any>(this.updateMailingAddress, obj);
    }

    GetMObileNotificationDetails(obj): Observable<any[]> {
        return this.http.get<any[]>(this.getMObileNotificationDetails+"?entityid="+obj);
    }
    GetAdvisorSite(): Observable<any[]> {
        return this.http.get<any[]>(this.getAdvisorSite);
    }
    GetAdvisorSiteByUserID(): Observable<any[]> {
        return this.http.get<any[]>(this.getAdvisorSiteByUserID+"?userid="+localStorage.Uid);
    }
    UpdateAdvisorSite(obj: any): Observable<any> {
        return this.http.post<any>(this.updateAdvisorSite, obj);
    }

    ChangePassword(obj:any):Observable<any>
    {
        return this.http.post<any>(this.changepassword, obj);
    }
    ResetPassword(obj:any):Observable<any>
    {
        return this.http.post<any>(this.resetpassword, obj);
    }
    //     getAllUsers(): Observable<UsersList> {
    //     return this.http.get<UsersList>(this.getAllUsersApi);
    // }
} 
