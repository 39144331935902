import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Menu } from './menu.model';
import { verticalMenuItems, horizontalMenuItems,UserhorizontalMenuItems,UserverticalMenuItems,SubscribUserverticalMenuItems,superAdminMenuItems } from './menu';

import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MenuService {

  constructor(private location:Location,
              private router:Router,public http: HttpClient){ } 
    
  public getVerticalMenuItems():Array<Menu> {
   
    // this._authService.getUserRole().subscribe(res=>
    //   {
    //     debugger;
    //     alert(JSON.stringify(res.data));
    //   })
    if(localStorage["UserRole"]=="5")
    {
    return superAdminMenuItems;
    }
    else
    if(localStorage["UserRole"]=="1")
    {
    return verticalMenuItems;
    }
    else if(localStorage["UserRole"]=="2")
    {
     return UserverticalMenuItems;
      // let currentmenu:Menu[]=UserverticalMenuItems;
      // this.GetUserSubscriptionDetails().subscribe(res=>
      //   {
      //     if(res.data[0].Status=="succeeded" && Number(res.data[0].dayscount)<30 )
      //     {
      //      // alert(res.data[0].dayscount);

      //      currentmenu= SubscribUserverticalMenuItems;
      //     }
      //     else
      //     {
      //       currentmenu=   UserverticalMenuItems;
      //     }
   
      //   });
       
       
      //     return currentmenu;
        
 
     // SubscribUserverticalMenuItems
   
    }
    else if(localStorage["UserRole"]=="3")
    {
      return SubscribUserverticalMenuItems;
    }
  }
  public getVerticalMenuItemsByRole(role:number):Array<Menu> {
  
    // this._authService.getUserRole().subscribe(res=>
    //   {
    //     debugger;
    //     alert(JSON.stringify(res.data));
    //   })
    if(localStorage["UserRole"]=="5")
    {
    return superAdminMenuItems;
    }
    else
    if(role==1)
    {
    return verticalMenuItems;
    }
    else if(role==2)
    {
      return UserverticalMenuItems;
      // let currentmenu:Menu[]=UserverticalMenuItems;
      // this.GetUserSubscriptionDetails().subscribe(res=>
      //   {
      //     if(res.data[0].Status=="succeeded" && Number(res.data[0].dayscount)<30 )
      //     {
      //      // alert(res.data[0].dayscount);

      //      currentmenu= SubscribUserverticalMenuItems;
      //     }
      //     else
      //     {
      //       currentmenu=   UserverticalMenuItems;
      //     }
   
      //   });
      //   setTimeout(() => {
      //     alert(JSON.stringify(currentmenu));
      //     return currentmenu;}, 2000);

       
          
        
 
     // SubscribUserverticalMenuItems
   
    }
    else if(role==3)
    {
      
      return SubscribUserverticalMenuItems;
    }
  }
  public getHorizontalMenuItems():Array<Menu> {
    return horizontalMenuItems;
  }

  public expandActiveSubMenu(menu:Array<Menu>){
      let url = this.location.path();
      let routerLink = url; // url.substring(1, url.length);
      let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
      if(activeMenuItem[0]){
        let menuItem = activeMenuItem[0];
        while (menuItem.parentId != 0){  
          let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
          menuItem = parentMenuItem;
          this.toggleMenuItem(menuItem.id);
        }
      }
  }

  public toggleMenuItem(menuId){
    let menuItem = document.getElementById('menu-item-'+menuId);
    let subMenu = document.getElementById('sub-menu-'+menuId);  
    if(subMenu){
      if(subMenu.classList.contains('show')){
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      }
      else{
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }      
    }
  }

  public closeOtherSubMenus(menu:Array<Menu>, menuId){
    let currentMenuItem = menu.filter(item => item.id == menuId)[0]; 
    if(currentMenuItem.parentId == 0 && !currentMenuItem.target){
      menu.forEach(item => {
        if(item.id != menuId){
          let subMenu = document.getElementById('sub-menu-'+item.id);
          let menuItem = document.getElementById('menu-item-'+item.id);
          if(subMenu){
            if(subMenu.classList.contains('show')){
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }              
          } 
        }
      });
    }
  }

  public closeAllSubMenus(){
      let menu = document.getElementById("vertical-menu");
      if(menu){
          for (let i = 0; i < menu.children[0].children.length; i++) {
              let child = menu.children[0].children[i];
              if(child){
                  if(child.children[0].classList.contains('expanded')){
                      child.children[0].classList.remove('expanded');
                      child.children[1].classList.remove('show');
                  }
              }
          }
      }
  }
  public GetUserSubscriptionDetails(): Observable<any> {

   let baseUrl: string = environment.config.API;
    let url = baseUrl + 'Authenticate/GetUserSubscriptionDetails?uid='+localStorage.Uid;
    return this.http.post(url, {});
  }

}
