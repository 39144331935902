// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  config: {
//API: 'http://localhost:7847/'
API: 'https://lifeplanapi.gitait.com/'
//API: 'https://lifeplanliveapi.gitait.com/'
//API:'https://lifeplan360apiplus.azurewebsites.net/'
  }
};
export const itemPrice=
{
  price:199

};

/*//
 * In development mode, to inggnore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
