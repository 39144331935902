import { Menu } from './menu.model';

export const superAdminMenuItems = [
  new Menu(1, 'Dashboard', '/dashboard', null, 'dashboard', null, false, 0),
  new Menu(4, 'Users', '/users', null, 'group_add', null, false, 0),
  new Menu(5, 'Training', null, null, 'school', null, true, 0),
  new Menu(6, 'Resource Videos', '/Training', null, 'navigate_next', null, false, 5),
  //new Menu(7, 'Bootcamp Videos', '/Training/bootCampVideo', null, 'navigate_next', null, false, 5),
  new Menu(7, 'Events', '/Training/events', null, 'navigate_next', null, false, 5),
  new Menu(8, 'Resources', '/Training/Resources', null, 'navigate_next', null, false, 5),
 // new Menu(9, 'Bootcamp Handouts', '/Training/bootCampHandOuts', null, 'navigate_next', null, false, 5),
  //new Menu(10, 'Additional Videos', '/Training/additionaTraining', null, 'navigate_next', null, false, 5),
  //new Menu(11, 'How-To-Videos', '/Training/how-to-videos', null, 'navigate_next', null, false, 5),
  new Menu(25, 'Administration', null, null, 'group_add', null, true, 0),
  new Menu(26, 'Payment Transactions', '/Shop/PaymentTransactions', null, 'navigate_next', null, false, 25),
  new Menu(26, 'Subscriptions', '/Shop/subscriptions', null, 'navigate_next', null, false, 25)
]
export const verticalMenuItems = [
  new Menu(1, 'Dashboard', '/dashboard', null, 'dashboard', null, false, 0),
  new Menu(2, 'Clients', '/Clients', null, 'gamepad', null, false, 0),
  new Menu(3, 'Leads', '/Leads', null, 'device_hub', null, false, 0),
  new Menu(4, 'Users', '/users', null, 'group_add', null, false, 0),
  new Menu(5, 'Training', null, null, 'school', null, true, 0),
  new Menu(6, 'Resource Videos', '/Training', null, 'navigate_next', null, false, 5),
  // new Menu(7, 'Bootcamp Videos', '/Training/bootCampVideo', null, 'navigate_next', null, false, 5),
  new Menu(7, 'Events', '/Training/events', null, 'navigate_next', null, false, 5),
  new Menu(8, 'Resources', '/Training/Resources', null, 'navigate_next', null, false, 5),
 // new Menu(9, 'Bootcamp Handouts', '/Training/bootCampHandOuts', null, 'navigate_next', null, false, 5),
  //new Menu(10, 'Additional Videos', '/Training/additionaTraining', null, 'navigate_next', null, false, 5),
  //new Menu(11, 'How-To-Videos', '/Training/how-to-videos', null, 'navigate_next', null, false, 5),
  new Menu(12, 'Profile', null, null, 'assignment_ind', null, true, 0),
  new Menu(13, 'My Profile', '/ProfileDetails', null, 'navigate_next', null, false, 12),
  new Menu(14, 'Mailing Address', '/ProfileDetails/mailingAddress', null, 'navigate_next', null, false, 12),
  //new Menu(15, 'Mobile Phone', '/ProfileDetails/mobile_phone', null, 'navigate_next', null, false, 12),
 // new Menu(16, 'SMS Settings', '/ProfileDetails/sms-settings', null, 'navigate_next', null, false, 12),
  new Menu(17, 'Billing Details', '/ProfileDetails/billingDetails', null, 'navigate_next', null, false, 12),
  //new Menu(18, 'Advisor Site', '/ProfileDetails/advisorSite', null, 'navigate_next', null, false, 12),
  new Menu(19, 'LP360 Plus Email Address', '/ProfileDetails/lp-360-Email-Address', null, 'navigate_next', null, false, 12),
  new Menu(20, 'Referrals', '/ProfileDetails/Referrals', null, 'navigate_next', null, false, 12),
  new Menu(21, 'Change Password', '/ProfileDetails/ChangePassword', null, 'navigate_nextt', null, false, 12),
  new Menu(22, 'Support', null, null, 'contact_support', null, true, 0),
  new Menu(6, 'FAQ', '/Support', null, 'navigate_next', null, false, 22),
 // new Menu(20, 'Bug Report', '/Support/BugReport', null, 'navigate_next', null, false, 22),
  //new Menu(21, 'Known Issues', '/Support/knownIssues', null, 'navigate_nextt', null, false, 22),
  new Menu(23, 'Shop', null, null, 'add_shopping_cart', null, true, 0),
  new Menu(24, 'Products', '/Shop', null, 'navigate_next', null, false, 23),
  new Menu(25, 'Administration', null, null, 'group_add', null, true, 0),
  new Menu(26, 'Payment Transactions', '/Shop/PaymentTransactions', null, 'navigate_next', null, false, 25)
  // new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
  // new Menu (2, 'Clients', null, null, 'supervisor_account', null, false, 0), 
  // new Menu (2, 'Users', '/users', null, 'supervisor_account', null, false, 0), 
  // new Menu (2, 'Training', '/Training', null, 'supervisor_account', null, false, 0), 
  // new Menu (7, 'Additional Training', '/Training/additionaTraining', null, 'supervisor_account', null, false, 3),
  // new Menu (8, 'BootCamp Handouts Training', '/Training/bootcampHandOuts', null, 'supervisor_account', null, false, 3),
  // new Menu (10, 'My Profile', '/ProfileDetails', null, 'supervisor_account', null, false, 4),
  // new Menu (11, 'profile UserInfo', '/ProfileDetails/profileUserInfo', null, 'supervisor_account', null, false, 4),
  // new Menu (12, 'Leads', '/Leads', null, 'supervisor_account', null, false, 0),
  // // new Menu (9, 'profile', '/profile', null, 'supervisor_account', null, false, 0), 
  // // new Menu (10, 'profile', '/profile/projects', null, 'note', null, false, 50),    
  // // new Menu (11, 'User Info', '/profile/user-info', null, 'perm_contact_calendar', null, false, 50),
  // new Menu (3, 'UI Features', null, null, 'computer', null, true, 0),   
  // new Menu (4, 'Buttons', '/ui/buttons', null, 'keyboard', null, false, 3),  
  // new Menu (5, 'Cards', '/ui/cards', null, 'card_membership', null, false, 3), 
  // new Menu (6, 'Lists', '/ui/lists', null, 'list', null, false, 3), 
  // new Menu (7, 'Grids', '/ui/grids', null, 'grid_on', null, false, 3), 
  // new Menu (8, 'Tabs', '/ui/tabs', null, 'tab', null, false, 3), 
  // new Menu (9, 'Expansion Panel', '/ui/expansion-panel', null, 'dns', null, false, 3),
  // new Menu (10, 'Chips', '/ui/chips', null, 'label', null, false, 3),
  // new Menu (11, 'Progress', '/ui/progress', null, 'data_usage', null, false, 3), 
  // new Menu (12, 'Dialog', '/ui/dialog', null, 'open_in_new', null, false, 3), 
  // new Menu (13, 'Tooltip', '/ui/tooltip', null, 'chat_bubble', null, false, 3), 
  // new Menu (14, 'Snackbar', '/ui/snack-bar', null, 'sms', null, false, 3), 
  // new Menu (15, 'Dynamic Menu', '/dynamic-menu', null, 'format_list_bulleted', null, false, 0),    
  // new Menu (16, 'Mailbox', '/mailbox', null, 'email', null, false, 0),
  // new Menu (17, 'Chat', '/chat', null, 'chat', null, false, 0),
  // new Menu (20, 'Form Controls', null, null, 'dvr', null, true, 0), 
  // new Menu (21, 'Autocomplete', '/form-controls/autocomplete', null, 'short_text', null, false, 20), 
  // new Menu (22, 'Checkbox', '/form-controls/checkbox', null, 'check_box', null, false, 20), 
  // new Menu (23, 'Datepicker', '/form-controls/datepicker', null, 'today', null, false, 20), 
  // new Menu (24, 'Form field', '/form-controls/form-field', null, 'view_stream', null, false, 20), 
  // new Menu (25, 'Input', '/form-controls/input', null, 'input', null, false, 20), 
  // new Menu (26, 'Radio button', '/form-controls/radio-button', null, 'radio_button_checked', null, false, 20), 
  // new Menu (27, 'Select', '/form-controls/select', null, 'playlist_add_check', null, false, 20), 
  // new Menu (28, 'Slider', '/form-controls/slider', null, 'tune', null, false, 20), 
  // new Menu (29, 'Slide toggle', '/form-controls/slide-toggle', null, 'star_half', null, false, 20), 
  // new Menu (30, 'Tables', null, null, 'view_module', null, true, 0),
  // new Menu (31, 'Basic', '/tables/basic', null, 'view_column', null, false, 30), 
  // new Menu (32, 'Paging', '/tables/paging', null, 'last_page', null, false, 30), 
  // new Menu (33, 'Sorting', '/tables/sorting', null, 'sort', null, false, 30),
  // new Menu (34, 'Filtering', '/tables/filtering', null, 'format_line_spacing', null, false, 30),
  // new Menu (35, 'Selecting', '/tables/selecting', null, 'playlist_add_check', null, false, 30),
  // new Menu (36, 'NGX DataTable', '/tables/ngx-table', null, 'view_array', null, false, 30), 
  // new Menu (40, 'Pages', null, null, 'library_books', null, true, 0),
  // new Menu (43, 'Login', '/login', null, 'exit_to_app', null, false, 40),    
  // new Menu (44, 'Register', '/register', null, 'person_add', null, false, 40),
  // new Menu (45, 'Blank', '/blank', null, 'check_box_outline_blank', null, false, 40),
  // new Menu (46, 'Page Not Found', '/pagenotfound', null, 'error_outline', null, false, 40),
  // new Menu (47, 'Error', '/error', null, 'warning', null, false, 40),
  // new Menu (48, 'Search', '/search', null, 'search', null, false, 40),
  // new Menu (49, 'Landing', '/landing', null, 'filter', null, false, 40),


  // new Menu (55, 'Schedule', '/schedule', null, 'event_note', null, false, 0),
  // new Menu (66, 'Maps', null, null, 'map', null, true, 0),
  // new Menu (67, 'Google Maps', '/maps/googlemaps', null, 'location_on', null, false, 66),
  // new Menu (68, 'Leaflet Maps', '/maps/leafletmaps', null, 'my_location', null, false, 66),
  // new Menu (70, 'Charts', null, null, 'multiline_chart', null, true, 0),
  // new Menu (71, 'Bar Charts', '/charts/bar', null, 'insert_chart', null, false, 70),
  // new Menu (72, 'Pie Charts', '/charts/pie', null, 'pie_chart', null, false, 70),
  // new Menu (73, 'Line Charts', '/charts/line', null, 'show_chart', null, false, 70),
  // new Menu (74, 'Bubble Charts', '/charts/bubble', null, 'bubble_chart', null, false, 70), 
  // new Menu (81, 'Drag & Drop', '/drag-drop', null, 'mouse', null, false, 0),  
  // new Menu (85, 'Material Icons', '/icons', null, 'insert_emoticon', null, false, 0),  
  // new Menu (140, 'Level 1', null, null, 'more_horiz', null, true, 0),
  // new Menu (141, 'Level 2', null, null, 'folder_open', null, true, 140),
  // new Menu (142, 'Level 3', null, null, 'folder_open', null, true, 141),
  // new Menu (143, 'Level 4', null, null, 'folder_open', null, true, 142),
  // new Menu (144, 'Level 5', null, 'http://themeseason.com', 'link', null, false, 143),
  // new Menu (200, 'External Link', null, 'http://themeseason.com', 'open_in_new', '_blank', false, 0)
]
export const UserverticalMenuItems = [
  new Menu(1, 'Dashboard', '/Shop', null, 'dashboard', null, false, 0),
  // new Menu (2, 'Clients','/Clients', null, 'gamepad', null, false, 0), 
  // new Menu (3, 'Leads', '/Leads', null, 'device_hub', null, false, 0),
  // new Menu (4, 'Users', '/users', null, 'group_add', null, false, 0),
  // new Menu (5, 'Training', null, null, 'school', null, true, 0), 
  // new Menu (6, 'Required Videos', '/Training', null, 'navigate_next', null, false, 5),
  // new Menu (7, 'Bootcamp Videos', '/Training/bootCampVideo', null, 'navigate_next', null, false,5),
  //  new Menu (8, 'Resources', '/Training/Resources', null, 'navigate_next', null, false, 5),  
  // new Menu (9, 'Bootcamp Handouts','/Training/bootCampHandOuts', null, 'navigate_next', null, false,5),  
  //  new Menu (10, 'Additional Training', '/Training/additionaTraining', null, 'navigate_next', null, false, 5),
  //  new Menu (11, 'How-To-Videos', '/Training/how-to-videos', null, 'navigate_next', null, false,5),
  new Menu(12, 'Profile', null, null, 'assignment_ind', null, true, 0),
  new Menu(13, 'Your Details', '/ProfileDetails', null, 'navigate_next', null, false, 12),
  // new Menu (14, 'Mailing Address', '/ProfileDetails/mailingAddress', null, 'navigate_next', null, false, 12),
  //new Menu(15, 'Mobile Phone', '/ProfileDetails/mobile_phone', null, 'navigate_next', null, false, 12),
  //new Menu(16, 'SMS Settings', '/ProfileDetails/sms-settings', null, 'navigate_next', null, false, 12),
  //new Menu (17, 'Billing Details', '/ProfileDetails/billingDetails', null, 'navigate_next', null, false, 12),
  //new Menu (18, 'Advisor Site', '/ProfileDetails/advisorSite', null, 'navigate_next', null, false, 12),
  // new Menu (19, 'LP360 Email Address', '/ProfileDetails/lp-360-Email-Address', null, 'navigate_next', null, false, 12),
  //  new Menu (20, 'Referrals', '/ProfileDetails/Referrals', null, 'navigate_next', null, false, 12),
  new Menu(21, 'Change Password', '/ProfileDetails/ChangePassword', null, 'navigate_nextt', null, false, 12),
  new Menu(22, 'Support', null, null, 'contact_support', null, true, 0),
   new Menu (6, 'FAQ', '/Support', null, 'navigate_next', null, false, 22),
//  new Menu(20, 'Bug Report', '/Support/BugReport', null, 'navigate_next', null, false, 22),
//  new Menu(21, 'Known Issues', '/Support/knownIssues', null, 'navigate_nextt', null, false, 22),
  new Menu(23, 'Shop', null, null, 'add_shopping_cart', null, true, 0),
  new Menu(24, 'Products', '/Shop', null, 'navigate_next', null, false, 23),
  // new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
  // new Menu (2, 'Clients', null, null, 'supervisor_account', null, false, 0), 
  // new Menu (2, 'Users', '/users', null, 'supervisor_account', null, false, 0), 
  // new Menu (2, 'Training', '/Training', null, 'supervisor_account', null, false, 0), 
  // new Menu (7, 'Additional Training', '/Training/additionaTraining', null, 'supervisor_account', null, false, 3),
  // new Menu (8, 'BootCamp Handouts Training', '/Training/bootcampHandOuts', null, 'supervisor_account', null, false, 3),
  // new Menu (10, 'My Profile', '/ProfileDetails', null, 'supervisor_account', null, false, 4),
  // new Menu (11, 'profile UserInfo', '/ProfileDetails/profileUserInfo', null, 'supervisor_account', null, false, 4),
  // new Menu (12, 'Leads', '/Leads', null, 'supervisor_account', null, false, 0),
  // // new Menu (9, 'profile', '/profile', null, 'supervisor_account', null, false, 0), 
  // // new Menu (10, 'profile', '/profile/projects', null, 'note', null, false, 50),    
  // // new Menu (11, 'User Info', '/profile/user-info', null, 'perm_contact_calendar', null, false, 50),
  // new Menu (3, 'UI Features', null, null, 'computer', null, true, 0),   
  // new Menu (4, 'Buttons', '/ui/buttons', null, 'keyboard', null, false, 3),  
  // new Menu (5, 'Cards', '/ui/cards', null, 'card_membership', null, false, 3), 
  // new Menu (6, 'Lists', '/ui/lists', null, 'list', null, false, 3), 
  // new Menu (7, 'Grids', '/ui/grids', null, 'grid_on', null, false, 3), 
  // new Menu (8, 'Tabs', '/ui/tabs', null, 'tab', null, false, 3), 
  // new Menu (9, 'Expansion Panel', '/ui/expansion-panel', null, 'dns', null, false, 3),
  // new Menu (10, 'Chips', '/ui/chips', null, 'label', null, false, 3),
  // new Menu (11, 'Progress', '/ui/progress', null, 'data_usage', null, false, 3), 
  // new Menu (12, 'Dialog', '/ui/dialog', null, 'open_in_new', null, false, 3), 
  // new Menu (13, 'Tooltip', '/ui/tooltip', null, 'chat_bubble', null, false, 3), 
  // new Menu (14, 'Snackbar', '/ui/snack-bar', null, 'sms', null, false, 3), 
  // new Menu (15, 'Dynamic Menu', '/dynamic-menu', null, 'format_list_bulleted', null, false, 0),    
  // new Menu (16, 'Mailbox', '/mailbox', null, 'email', null, false, 0),
  // new Menu (17, 'Chat', '/chat', null, 'chat', null, false, 0),
  // new Menu (20, 'Form Controls', null, null, 'dvr', null, true, 0), 
  // new Menu (21, 'Autocomplete', '/form-controls/autocomplete', null, 'short_text', null, false, 20), 
  // new Menu (22, 'Checkbox', '/form-controls/checkbox', null, 'check_box', null, false, 20), 
  // new Menu (23, 'Datepicker', '/form-controls/datepicker', null, 'today', null, false, 20), 
  // new Menu (24, 'Form field', '/form-controls/form-field', null, 'view_stream', null, false, 20), 
  // new Menu (25, 'Input', '/form-controls/input', null, 'input', null, false, 20), 
  // new Menu (26, 'Radio button', '/form-controls/radio-button', null, 'radio_button_checked', null, false, 20), 
  // new Menu (27, 'Select', '/form-controls/select', null, 'playlist_add_check', null, false, 20), 
  // new Menu (28, 'Slider', '/form-controls/slider', null, 'tune', null, false, 20), 
  // new Menu (29, 'Slide toggle', '/form-controls/slide-toggle', null, 'star_half', null, false, 20), 
  // new Menu (30, 'Tables', null, null, 'view_module', null, true, 0),
  // new Menu (31, 'Basic', '/tables/basic', null, 'view_column', null, false, 30), 
  // new Menu (32, 'Paging', '/tables/paging', null, 'last_page', null, false, 30), 
  // new Menu (33, 'Sorting', '/tables/sorting', null, 'sort', null, false, 30),
  // new Menu (34, 'Filtering', '/tables/filtering', null, 'format_line_spacing', null, false, 30),
  // new Menu (35, 'Selecting', '/tables/selecting', null, 'playlist_add_check', null, false, 30),
  // new Menu (36, 'NGX DataTable', '/tables/ngx-table', null, 'view_array', null, false, 30), 
  // new Menu (40, 'Pages', null, null, 'library_books', null, true, 0),
  // new Menu (43, 'Login', '/login', null, 'exit_to_app', null, false, 40),    
  // new Menu (44, 'Register', '/register', null, 'person_add', null, false, 40),
  // new Menu (45, 'Blank', '/blank', null, 'check_box_outline_blank', null, false, 40),
  // new Menu (46, 'Page Not Found', '/pagenotfound', null, 'error_outline', null, false, 40),
  // new Menu (47, 'Error', '/error', null, 'warning', null, false, 40),
  // new Menu (48, 'Search', '/search', null, 'search', null, false, 40),
  // new Menu (49, 'Landing', '/landing', null, 'filter', null, false, 40),


  // new Menu (55, 'Schedule', '/schedule', null, 'event_note', null, false, 0),
  // new Menu (66, 'Maps', null, null, 'map', null, true, 0),
  // new Menu (67, 'Google Maps', '/maps/googlemaps', null, 'location_on', null, false, 66),
  // new Menu (68, 'Leaflet Maps', '/maps/leafletmaps', null, 'my_location', null, false, 66),
  // new Menu (70, 'Charts', null, null, 'multiline_chart', null, true, 0),
  // new Menu (71, 'Bar Charts', '/charts/bar', null, 'insert_chart', null, false, 70),
  // new Menu (72, 'Pie Charts', '/charts/pie', null, 'pie_chart', null, false, 70),
  // new Menu (73, 'Line Charts', '/charts/line', null, 'show_chart', null, false, 70),
  // new Menu (74, 'Bubble Charts', '/charts/bubble', null, 'bubble_chart', null, false, 70), 
  // new Menu (81, 'Drag & Drop', '/drag-drop', null, 'mouse', null, false, 0),  
  // new Menu (85, 'Material Icons', '/icons', null, 'insert_emoticon', null, false, 0),  
  // new Menu (140, 'Level 1', null, null, 'more_horiz', null, true, 0),
  // new Menu (141, 'Level 2', null, null, 'folder_open', null, true, 140),
  // new Menu (142, 'Level 3', null, null, 'folder_open', null, true, 141),
  // new Menu (143, 'Level 4', null, null, 'folder_open', null, true, 142),
  // new Menu (144, 'Level 5', null, 'http://themeseason.com', 'link', null, false, 143),
  // new Menu (200, 'External Link', null, 'http://themeseason.com', 'open_in_new', '_blank', false, 0)
]
export const SubscribUserverticalMenuItems = [
  new Menu(1, 'Dashboard', '/dashboard', null, 'dashboard', null, false, 0),
  new Menu(2, 'Clients', '/Clients', null, 'gamepad', null, false, 0),
  new Menu(3, 'Leads', '/Leads', null, 'device_hub', null, false, 0),
  new Menu(4, 'Users', '/users', null, 'group_add', null, false, 0),
  new Menu (5, 'Training', null, null, 'school', null, true, 0), 
  new Menu (6, 'Required Videos', '/Training', null, 'navigate_next', null, false, 5),
   //new Menu (7, 'Bootcamp Videos', '/Training/bootCampVideo', null, 'navigate_next', null, false,5),
   new Menu (7, 'Events', '/Training/events', null, 'navigate_next', null, false,5),
    new Menu (8, 'Resources', '/Training/Resources', null, 'navigate_next', null, false, 5),  
  // new Menu (9, 'Bootcamp Handouts','/Training/bootCampHandOuts', null, 'navigate_next', null, false,5),  
  //  new Menu (10, 'Additional Training', '/Training/additionaTraining', null, 'navigate_next', null, false, 5),
  //  new Menu (11, 'How-To-Videos', '/Training/how-to-videos', null, 'navigate_next', null, false,5),
  new Menu(12, 'Profile', null, null, 'assignment_ind', null, true, 0),
  new Menu(13, 'Your Details', '/ProfileDetails', null, 'navigate_next', null, false, 12),
  // new Menu (14, 'Mailing Address', '/ProfileDetails/mailingAddress', null, 'navigate_next', null, false, 12),
  //new Menu(15, 'Mobile Phone', '/ProfileDetails/mobile_phone', null, 'navigate_next', null, false, 12),
  //new Menu(16, 'SMS Settings', '/ProfileDetails/sms-settings', null, 'navigate_next', null, false, 12),
  new Menu (17, 'Billing Details', '/ProfileDetails/billingDetails', null, 'navigate_next', null, false, 12),
  //new Menu (18, 'Advisor Site', '/ProfileDetails/advisorSite', null, 'navigate_next', null, false, 12),
  // new Menu (19, 'LP360 Email Address', '/ProfileDetails/lp-360-Email-Address', null, 'navigate_next', null, false, 12),
  new Menu(20, 'Referrals', '/ProfileDetails/Referrals', null, 'navigate_next', null, false, 12),
  new Menu(21, 'Change Password', '/ProfileDetails/ChangePassword', null, 'navigate_nextt', null, false, 12),
  new Menu(22, 'Support', null, null, 'contact_support', null, true, 0),
   new Menu (6, 'FAQ', '/Support', null, 'navigate_next', null, false, 22),
 // new Menu(20, 'Bug Report', '/Support/BugReport', null, 'navigate_next', null, false, 22),
  //new Menu(21, 'Known Issues', '/Support/knownIssues', null, 'navigate_nextt', null, false, 22),
  new Menu(23, 'Shop', null, null, 'add_shopping_cart', null, true, 0),
  new Menu(24, 'Products', '/Shop', null, 'navigate_next', null, false, 23),
  // new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
  // new Menu (2, 'Clients', null, null, 'supervisor_account', null, false, 0), 
  // new Menu (2, 'Users', '/users', null, 'supervisor_account', null, false, 0), 
  // new Menu (2, 'Training', '/Training', null, 'supervisor_account', null, false, 0), 
  // new Menu (7, 'Additional Training', '/Training/additionaTraining', null, 'supervisor_account', null, false, 3),
  // new Menu (8, 'BootCamp Handouts Training', '/Training/bootcampHandOuts', null, 'supervisor_account', null, false, 3),
  // new Menu (10, 'My Profile', '/ProfileDetails', null, 'supervisor_account', null, false, 4),
  // new Menu (11, 'profile UserInfo', '/ProfileDetails/profileUserInfo', null, 'supervisor_account', null, false, 4),
  // new Menu (12, 'Leads', '/Leads', null, 'supervisor_account', null, false, 0),
  // // new Menu (9, 'profile', '/profile', null, 'supervisor_account', null, false, 0), 
  // // new Menu (10, 'profile', '/profile/projects', null, 'note', null, false, 50),    
  // // new Menu (11, 'User Info', '/profile/user-info', null, 'perm_contact_calendar', null, false, 50),
  // new Menu (3, 'UI Features', null, null, 'computer', null, true, 0),   
  // new Menu (4, 'Buttons', '/ui/buttons', null, 'keyboard', null, false, 3),  
  // new Menu (5, 'Cards', '/ui/cards', null, 'card_membership', null, false, 3), 
  // new Menu (6, 'Lists', '/ui/lists', null, 'list', null, false, 3), 
  // new Menu (7, 'Grids', '/ui/grids', null, 'grid_on', null, false, 3), 
  // new Menu (8, 'Tabs', '/ui/tabs', null, 'tab', null, false, 3), 
  // new Menu (9, 'Expansion Panel', '/ui/expansion-panel', null, 'dns', null, false, 3),
  // new Menu (10, 'Chips', '/ui/chips', null, 'label', null, false, 3),
  // new Menu (11, 'Progress', '/ui/progress', null, 'data_usage', null, false, 3), 
  // new Menu (12, 'Dialog', '/ui/dialog', null, 'open_in_new', null, false, 3), 
  // new Menu (13, 'Tooltip', '/ui/tooltip', null, 'chat_bubble', null, false, 3), 
  // new Menu (14, 'Snackbar', '/ui/snack-bar', null, 'sms', null, false, 3), 
  // new Menu (15, 'Dynamic Menu', '/dynamic-menu', null, 'format_list_bulleted', null, false, 0),    
  // new Menu (16, 'Mailbox', '/mailbox', null, 'email', null, false, 0),
  // new Menu (17, 'Chat', '/chat', null, 'chat', null, false, 0),
  // new Menu (20, 'Form Controls', null, null, 'dvr', null, true, 0), 
  // new Menu (21, 'Autocomplete', '/form-controls/autocomplete', null, 'short_text', null, false, 20), 
  // new Menu (22, 'Checkbox', '/form-controls/checkbox', null, 'check_box', null, false, 20), 
  // new Menu (23, 'Datepicker', '/form-controls/datepicker', null, 'today', null, false, 20), 
  // new Menu (24, 'Form field', '/form-controls/form-field', null, 'view_stream', null, false, 20), 
  // new Menu (25, 'Input', '/form-controls/input', null, 'input', null, false, 20), 
  // new Menu (26, 'Radio button', '/form-controls/radio-button', null, 'radio_button_checked', null, false, 20), 
  // new Menu (27, 'Select', '/form-controls/select', null, 'playlist_add_check', null, false, 20), 
  // new Menu (28, 'Slider', '/form-controls/slider', null, 'tune', null, false, 20), 
  // new Menu (29, 'Slide toggle', '/form-controls/slide-toggle', null, 'star_half', null, false, 20), 
  // new Menu (30, 'Tables', null, null, 'view_module', null, true, 0),
  // new Menu (31, 'Basic', '/tables/basic', null, 'view_column', null, false, 30), 
  // new Menu (32, 'Paging', '/tables/paging', null, 'last_page', null, false, 30), 
  // new Menu (33, 'Sorting', '/tables/sorting', null, 'sort', null, false, 30),
  // new Menu (34, 'Filtering', '/tables/filtering', null, 'format_line_spacing', null, false, 30),
  // new Menu (35, 'Selecting', '/tables/selecting', null, 'playlist_add_check', null, false, 30),
  // new Menu (36, 'NGX DataTable', '/tables/ngx-table', null, 'view_array', null, false, 30), 
  // new Menu (40, 'Pages', null, null, 'library_books', null, true, 0),
  // new Menu (43, 'Login', '/login', null, 'exit_to_app', null, false, 40),    
  // new Menu (44, 'Register', '/register', null, 'person_add', null, false, 40),
  // new Menu (45, 'Blank', '/blank', null, 'check_box_outline_blank', null, false, 40),
  // new Menu (46, 'Page Not Found', '/pagenotfound', null, 'error_outline', null, false, 40),
  // new Menu (47, 'Error', '/error', null, 'warning', null, false, 40),
  // new Menu (48, 'Search', '/search', null, 'search', null, false, 40),
  // new Menu (49, 'Landing', '/landing', null, 'filter', null, false, 40),


  // new Menu (55, 'Schedule', '/schedule', null, 'event_note', null, false, 0),
  // new Menu (66, 'Maps', null, null, 'map', null, true, 0),
  // new Menu (67, 'Google Maps', '/maps/googlemaps', null, 'location_on', null, false, 66),
  // new Menu (68, 'Leaflet Maps', '/maps/leafletmaps', null, 'my_location', null, false, 66),
  // new Menu (70, 'Charts', null, null, 'multiline_chart', null, true, 0),
  // new Menu (71, 'Bar Charts', '/charts/bar', null, 'insert_chart', null, false, 70),
  // new Menu (72, 'Pie Charts', '/charts/pie', null, 'pie_chart', null, false, 70),
  // new Menu (73, 'Line Charts', '/charts/line', null, 'show_chart', null, false, 70),
  // new Menu (74, 'Bubble Charts', '/charts/bubble', null, 'bubble_chart', null, false, 70), 
  // new Menu (81, 'Drag & Drop', '/drag-drop', null, 'mouse', null, false, 0),  
  // new Menu (85, 'Material Icons', '/icons', null, 'insert_emoticon', null, false, 0),  
  // new Menu (140, 'Level 1', null, null, 'more_horiz', null, true, 0),
  // new Menu (141, 'Level 2', null, null, 'folder_open', null, true, 140),
  // new Menu (142, 'Level 3', null, null, 'folder_open', null, true, 141),
  // new Menu (143, 'Level 4', null, null, 'folder_open', null, true, 142),
  // new Menu (144, 'Level 5', null, 'http://themeseason.com', 'link', null, false, 143),
  // new Menu (200, 'External Link', null, 'http://themeseason.com', 'open_in_new', '_blank', false, 0)
]

export const horizontalMenuItems = [
  new Menu(1, 'Dashboard', '/Clients', null, 'dashboard', null, false, 0),
  new Menu(2, 'Clients', '/Clients', null, 'supervisor_account', null, false, 0),
  new Menu(3, 'Leads', '/Leads', null, 'supervisor_account', null, false, 0),
  new Menu(4, 'Users', '/users', null, 'supervisor_account', null, false, 0),
  new Menu(5, 'Training', null, null, 'supervisor_account', null, true, 0),
  new Menu(4, 'Resource Videos', '/Training', null, 'supervisor_account', null, false, 5),
  //new Menu(5, 'Bootcamp Videos', '/Training/bootCampVideo', null, 'supervisor_account', null, false, 5),
  new Menu(5, 'Events', '/Training/events', null, 'supervisor_account', null, false, 5),
  new Menu(6, 'Resources', '/Training/Resources', null, 'supervisor_account', null, false, 5),
//  new Menu(7, 'Bootcamp Handouts', '/Training/bootCampHandOuts', null, 'supervisor_account', null, false, 5),
 // new Menu(8, 'Additional Videos', '/Training/additionaTraining', null, 'supervisor_account', null, false, 5),
//  new Menu(9, 'How-To-Videos', '/Training/how-to-videos', null, 'supervisor_account', null, false, 5),
  new Menu(6, 'Profile', null, null, 'supervisor_account', null, true, 0),
  new Menu(10, 'My Profile', '/ProfileDetails', null, 'supervisor_account', null, false, 6),
  new Menu(11, 'Mailing Address', '/ProfileDetails/mailingAddress', null, 'supervisor_account', null, false, 6),
  //new Menu(12, 'Mobile Phone', '/ProfileDetails/mobile_phone', null, 'supervisor_account', null, false, 6),
  //new Menu(13, 'Sms Settings', '/ProfileDetails/sms-settings', null, 'supervisor_account', null, false, 6),
  new Menu(14, 'Billing Details', '/ProfileDetails/billingDetails', null, 'supervisor_account', null, false, 6),
  //new Menu(15, 'Advisor Site', '/ProfileDetails/advisorSite', null, 'supervisor_account', null, false, 6),
  new Menu(16, 'Lp 360 Email Address', '/ProfileDetails/lp-360-Email-Address', null, 'supervisor_account', null, false, 6),
  new Menu(17, 'Referrals', '/ProfileDetails/Referrals', null, 'supervisor_account', null, false, 6),
  new Menu(18, 'ChangePassword', '/ProfileDetails/ChangePassword', null, 'supervisor_account', null, false, 6),
  // new Menu (10, 'profile', null, null, 'supervisor_account', null, true, 0), 
  // new Menu (11, 'projects', '/profile/projects', null, 'supervisor_account', null, false, 3),
  // new Menu (5, 'UserInfo', '/profile/user-info', null, 'supervisor_account', null, false, 3),
  // new Menu (6, ' Resources', '/Training/Resources', null, 'supervisor_account', null, false, 3),  
  // new Menu (7, ' BootCamp Handouts Training','/Training/bootCampHandOuts', null, 'supervisor_account', null, false, 3),  
  // new Menu (8, 'Additional Training', '/Training/additionaTraining', null, 'supervisor_account', null, false, 3),
  // new Menu (9, 'How-To-Videos', '/Training/how-to-videos', null, 'supervisor_account', null, false, 3),
  // new Menu (10, 'Profile', null, null, 'supervisor_account', null, true, 0), 
  // new Menu (11, 'profile', '/profile/projects', null, 'note', null, false, 50),    
  // new Menu (12, 'User Info', '/profile/user-info', null, 'perm_contact_calendar', null, false, 50),
  // new Menu (3, 'UI Features', null, null, 'computer', null, true, 0),   
  // new Menu (4, 'Buttons', '/ui/buttons', null, 'keyboard', null, false, 3),  
  // new Menu (5, 'Cards', '/ui/cards', null, 'card_membership', null, false, 3), 
  // new Menu (6, 'Lists', '/ui/lists', null, 'list', null, false, 3), 
  // new Menu (7, 'Grids', '/ui/grids', null, 'grid_on', null, false, 3), 
  // new Menu (8, 'Tabs', '/ui/tabs', null, 'tab', null, false, 3), 
  // new Menu (9, 'Expansion Panel', '/ui/expansion-panel', null, 'dns', null, false, 3), 
  // new Menu (10, 'Chips', '/ui/chips', null, 'label', null, false, 3),
  // new Menu (11, 'Progress', '/ui/progress', null, 'data_usage', null, false, 3), 
  // new Menu (12, 'Dialog', '/ui/dialog', null, 'open_in_new', null, false, 3), 
  // new Menu (13, 'Tooltip', '/ui/tooltip', null, 'chat_bubble', null, false, 3), 
  // new Menu (14, 'Snackbar', '/ui/snack-bar', null, 'sms', null, false, 3),
  // new Menu (16, 'Mailbox', '/mailbox', null, 'email', null, false, 40), 
  // new Menu (17, 'Chat', '/chat', null, 'chat', null, false, 40), 
  // new Menu (20, 'Form Controls', null, null, 'dvr', null, true, 0), 
  // new Menu (21, 'Autocomplete', '/form-controls/autocomplete', null, 'short_text', null, false, 20), 
  // new Menu (22, 'Checkbox', '/form-controls/checkbox', null, 'check_box', null, false, 20), 
  // new Menu (23, 'Datepicker', '/form-controls/datepicker', null, 'today', null, false, 20), 
  // new Menu (24, 'Form field', '/form-controls/form-field', null, 'view_stream', null, false, 20), 
  // new Menu (25, 'Input', '/form-controls/input', null, 'input', null, false, 20), 
  // new Menu (26, 'Radio button', '/form-controls/radio-button', null, 'radio_button_checked', null, false, 20), 
  // new Menu (27, 'Select', '/form-controls/select', null, 'playlist_add_check', null, false, 20), 
  // new Menu (28, 'Slider', '/form-controls/slider', null, 'tune', null, false, 20), 
  // new Menu (29, 'Slide toggle', '/form-controls/slide-toggle', null, 'star_half', null, false, 20),    
  // new Menu (30, 'Tables', null, null, 'view_module', null, true, 0),
  // new Menu (31, 'Basic', '/tables/basic', null, 'view_column', null, false, 30), 
  // new Menu (32, 'Paging', '/tables/paging', null, 'last_page', null, false, 30), 
  // new Menu (33, 'Sorting', '/tables/sorting', null, 'sort', null, false, 30),
  // new Menu (34, 'Filtering', '/tables/filtering', null, 'format_line_spacing', null, false, 30),
  // new Menu (35, 'Selecting', '/tables/selecting', null, 'playlist_add_check', null, false, 30),
  // new Menu (36, 'NGX DataTable', '/tables/ngx-table', null, 'view_array', null, false, 30),  
  // new Menu (70, 'Charts', null, null, 'multiline_chart', null, true, 0),
  // new Menu (71, 'Bar Charts', '/charts/bar', null, 'insert_chart', null, false, 70),
  // new Menu (72, 'Pie Charts', '/charts/pie', null, 'pie_chart', null, false, 70),
  // new Menu (73, 'Line Charts', '/charts/line', null, 'show_chart', null, false, 70),
  // new Menu (74, 'Bubble Charts', '/charts/bubble', null, 'bubble_chart', null, false, 70),    
  // new Menu (81, 'Drag & Drop', '/drag-drop', null, 'mouse', null, false, 3), 
  // new Menu (85, 'Material Icons', '/icons', null, 'insert_emoticon', null, false, 3),
  // new Menu (40, 'Pages', null, null, 'library_books', null, true, 0),
  // new Menu (43, 'Login', '/login', null, 'exit_to_app', null, false, 40),    
  // new Menu (44, 'Register', '/register', null, 'person_add', null, false, 40),
  // new Menu (45, 'Blank', '/blank', null, 'check_box_outline_blank', null, false, 40),
  // new Menu (46, 'Page Not Found', '/pagenotfound', null, 'error_outline', null, false, 40),
  // new Menu (47, 'Error', '/error', null, 'warning', null, false, 40),
  // new Menu (48, 'Search', '/search', null, 'search', null, false, 40),
  // new Menu (49, 'Landing', '/landing', null, 'filter', null, false, 40),
  // new Menu (50, 'Profile', null, null, 'person', null, true, 40),
  // new Menu (51, 'Projects', '/profile/projects', null, 'note', null, false, 50),    
  // new Menu (52, 'User Info', '/profile/user-info', null, 'perm_contact_calendar', null, false, 50),
  // new Menu (55, 'Schedule', '/schedule', null, 'event_note', null, false, 40),
  // new Menu (200, 'External Link', null, 'http://themeseason.com', 'open_in_new', '_blank', false, 40),
  // new Menu (66, 'Maps', null, null, 'map', null, true, 0),
  // new Menu (67, 'Google', '/maps/googlemaps', null, 'location_on', null, false, 66),
  // new Menu (68, 'Leaflet', '/maps/leafletmaps', null, 'my_location', null, false, 66),
]
export const UserhorizontalMenuItems = [
  new Menu(1, 'Dashboard', '/Clients', null, 'dashboard', null, false, 0),
  //  new Menu (2, 'Clients','/Clients', null, 'supervisor_account', null, false, 0), 
  //  new Menu (3, 'Leads', '/Leads', null, 'supervisor_account', null, false, 0),
  //  new Menu (4, 'Users', '/users', null, 'supervisor_account', null, false, 0),
  //  new Menu (5, 'Training', null, null, 'supervisor_account', null, true, 0), 
  //  new Menu (4, 'Required Videos', '/Training', null, 'supervisor_account', null, false, 5),
  //  new Menu (5, 'Bootcamp Videos', '/Training/bootCampVideo', null, 'supervisor_account', null, false,5),
  //  new Menu (6, 'Resources', '/Training/Resources', null, 'supervisor_account', null, false, 5),  
  //   new Menu (7, 'Bootcamp Handouts','/Training/bootCampHandOuts', null, 'supervisor_account', null, false,5),  
  //  new Menu (8, 'Additional Training', '/Training/additionaTraining', null, 'supervisor_account', null, false, 5),
  // new Menu (9, 'How-To-Videos', '/Training/how-to-videos', null, 'supervisor_account', null, false,5),
  new Menu(6, 'Profile', null, null, 'supervisor_account', null, true, 0),
  new Menu(10, 'Your Details', '/ProfileDetails', null, 'supervisor_account', null, false, 6),
  //  new Menu (11, 'Mailing Address', '/ProfileDetails/mailingAddress', null, 'supervisor_account', null, false, 6),
  //new Menu(12, 'Mobile Phone', '/ProfileDetails/mobile_phone', null, 'supervisor_account', null, false, 6),
 // new Menu(13, 'Sms Settings', '/ProfileDetails/sms-settings', null, 'supervisor_account', null, false, 6),
 //  new Menu (14, 'Billing Details', '/ProfileDetails/billingDetails', null, 'supervisor_account', null, false, 6),
  // new Menu (15, 'Advisor Site', '/ProfileDetails/advisorSite', null, 'supervisor_account', null, false, 6),
  //  new Menu (16, 'Lp 360 Email Address', '/ProfileDetails/lp-360-Email-Address', null, 'supervisor_account', null, false, 6),
  //  new Menu (17, 'Referrals', '/ProfileDetails/Referrals', null, 'supervisor_account', null, false, 6),
  new Menu(18, 'ChangePassword', '/ProfileDetails/ChangePassword', null, 'supervisor_account', null, false, 6),
  // new Menu (10, 'profile', null, null, 'supervisor_account', null, true, 0), 
  // new Menu (11, 'projects', '/profile/projects', null, 'supervisor_account', null, false, 3),
  // new Menu (5, 'UserInfo', '/profile/user-info', null, 'supervisor_account', null, false, 3),
  // new Menu (6, ' Resources', '/Training/Resources', null, 'supervisor_account', null, false, 3),  
  // new Menu (7, ' BootCamp Handouts Training','/Training/bootCampHandOuts', null, 'supervisor_account', null, false, 3),  
  // new Menu (8, 'Additional Training', '/Training/additionaTraining', null, 'supervisor_account', null, false, 3),
  // new Menu (9, 'How-To-Videos', '/Training/how-to-videos', null, 'supervisor_account', null, false, 3),
  // new Menu (10, 'Profile', null, null, 'supervisor_account', null, true, 0), 
  // new Menu (11, 'profile', '/profile/projects', null, 'note', null, false, 50),    
  // new Menu (12, 'User Info', '/profile/user-info', null, 'perm_contact_calendar', null, false, 50),
  // new Menu (3, 'UI Features', null, null, 'computer', null, true, 0),   
  // new Menu (4, 'Buttons', '/ui/buttons', null, 'keyboard', null, false, 3),  
  // new Menu (5, 'Cards', '/ui/cards', null, 'card_membership', null, false, 3), 
  // new Menu (6, 'Lists', '/ui/lists', null, 'list', null, false, 3), 
  // new Menu (7, 'Grids', '/ui/grids', null, 'grid_on', null, false, 3), 
  // new Menu (8, 'Tabs', '/ui/tabs', null, 'tab', null, false, 3), 
  // new Menu (9, 'Expansion Panel', '/ui/expansion-panel', null, 'dns', null, false, 3), 
  // new Menu (10, 'Chips', '/ui/chips', null, 'label', null, false, 3),
  // new Menu (11, 'Progress', '/ui/progress', null, 'data_usage', null, false, 3), 
  // new Menu (12, 'Dialog', '/ui/dialog', null, 'open_in_new', null, false, 3), 
  // new Menu (13, 'Tooltip', '/ui/tooltip', null, 'chat_bubble', null, false, 3), 
  // new Menu (14, 'Snackbar', '/ui/snack-bar', null, 'sms', null, false, 3),
  // new Menu (16, 'Mailbox', '/mailbox', null, 'email', null, false, 40), 
  // new Menu (17, 'Chat', '/chat', null, 'chat', null, false, 40), 
  // new Menu (20, 'Form Controls', null, null, 'dvr', null, true, 0), 
  // new Menu (21, 'Autocomplete', '/form-controls/autocomplete', null, 'short_text', null, false, 20), 
  // new Menu (22, 'Checkbox', '/form-controls/checkbox', null, 'check_box', null, false, 20), 
  // new Menu (23, 'Datepicker', '/form-controls/datepicker', null, 'today', null, false, 20), 
  // new Menu (24, 'Form field', '/form-controls/form-field', null, 'view_stream', null, false, 20), 
  // new Menu (25, 'Input', '/form-controls/input', null, 'input', null, false, 20), 
  // new Menu (26, 'Radio button', '/form-controls/radio-button', null, 'radio_button_checked', null, false, 20), 
  // new Menu (27, 'Select', '/form-controls/select', null, 'playlist_add_check', null, false, 20), 
  // new Menu (28, 'Slider', '/form-controls/slider', null, 'tune', null, false, 20), 
  // new Menu (29, 'Slide toggle', '/form-controls/slide-toggle', null, 'star_half', null, false, 20),    
  // new Menu (30, 'Tables', null, null, 'view_module', null, true, 0),
  // new Menu (31, 'Basic', '/tables/basic', null, 'view_column', null, false, 30), 
  // new Menu (32, 'Paging', '/tables/paging', null, 'last_page', null, false, 30), 
  // new Menu (33, 'Sorting', '/tables/sorting', null, 'sort', null, false, 30),
  // new Menu (34, 'Filtering', '/tables/filtering', null, 'format_line_spacing', null, false, 30),
  // new Menu (35, 'Selecting', '/tables/selecting', null, 'playlist_add_check', null, false, 30),
  // new Menu (36, 'NGX DataTable', '/tables/ngx-table', null, 'view_array', null, false, 30),  
  // new Menu (70, 'Charts', null, null, 'multiline_chart', null, true, 0),
  // new Menu (71, 'Bar Charts', '/charts/bar', null, 'insert_chart', null, false, 70),
  // new Menu (72, 'Pie Charts', '/charts/pie', null, 'pie_chart', null, false, 70),
  // new Menu (73, 'Line Charts', '/charts/line', null, 'show_chart', null, false, 70),
  // new Menu (74, 'Bubble Charts', '/charts/bubble', null, 'bubble_chart', null, false, 70),    
  // new Menu (81, 'Drag & Drop', '/drag-drop', null, 'mouse', null, false, 3), 
  // new Menu (85, 'Material Icons', '/icons', null, 'insert_emoticon', null, false, 3),
  // new Menu (40, 'Pages', null, null, 'library_books', null, true, 0),
  // new Menu (43, 'Login', '/login', null, 'exit_to_app', null, false, 40),    
  // new Menu (44, 'Register', '/register', null, 'person_add', null, false, 40),
  // new Menu (45, 'Blank', '/blank', null, 'check_box_outline_blank', null, false, 40),
  // new Menu (46, 'Page Not Found', '/pagenotfound', null, 'error_outline', null, false, 40),
  // new Menu (47, 'Error', '/error', null, 'warning', null, false, 40),
  // new Menu (48, 'Search', '/search', null, 'search', null, false, 40),
  // new Menu (49, 'Landing', '/landing', null, 'filter', null, false, 40),
  // new Menu (50, 'Profile', null, null, 'person', null, true, 40),
  // new Menu (51, 'Projects', '/profile/projects', null, 'note', null, false, 50),    
  // new Menu (52, 'User Info', '/profile/user-info', null, 'perm_contact_calendar', null, false, 50),
  // new Menu (55, 'Schedule', '/schedule', null, 'event_note', null, false, 40),
  // new Menu (200, 'External Link', null, 'http://themeseason.com', 'open_in_new', '_blank', false, 40),
  // new Menu (66, 'Maps', null, null, 'map', null, true, 0),
  // new Menu (67, 'Google', '/maps/googlemaps', null, 'location_on', null, false, 66),
  // new Menu (68, 'Leaflet', '/maps/leafletmaps', null, 'my_location', null, false, 66),
]