import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export class DateValidator {
  static dateVaidator(AC: AbstractControl) {
    if (AC && AC.value && !moment(AC.value, 'yyyy-MM-DD', true).isValid()) {
      return { 'dateVaidator': true };
    }
    return null;
  }
}
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'yyyy-MM-DD hh:mm'
},
display: {
    dateInput: 'yyyy-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
}
};